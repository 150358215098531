import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useStore } from "../../app/stores/store";
import SubnetDetailsCIDRItem from "./SubnetDetailsCIDRItem";
import SubnetDetailsIPItem from "./SubnetDetailsIPItem";
import SubnetDetailsItem from "./SubnetDetailsItem";

export default observer(function SubnetLink() {

    const baseUrl = "https://subnet.im/";

    const {subnetStore} = useStore();
    const {currentSubnet} = subnetStore;
    const [url, setUrl] = useState(baseUrl);
    
    
    useEffect(() => {
        if(currentSubnet) {
            setUrl(baseUrl + currentSubnet.ipLowStr + "/" + currentSubnet.prefixSize.toString());
        }
    }, [currentSubnet, setUrl]);

    if(subnetStore.currentSubnet == undefined) return null;

    function copyToClipboard()
    {
        document.execCommand("copy", false, url);
        toast.success("Copied link to clipboard!");
    }

    return (
        <div className="px-4 py-2 md:py-4">
            <div className="pb-2">
                  <div className="font-extrabold text-transparent text-xl bg-clip-text bg-gradient-to-r from-pink-600 to-purple-500 text-center pb-2"
                >Subnet Link</div>
                <input
                    value={url}
                    readOnly={true}
                    onFocus={(event) => event.target.select()}
                    onClick={() => copyToClipboard()}
                    className="font-sans
                        text-center
                        text-md
                        shadow
                        text-slate-800
                        tracking-widest
                        rounded-md
                        bg-emerald-50
                        py-2
                        w-full
                        hover:bg-slate-200"
                        />
                <div className="w-full text-center font-light text-slate-600 pt-2">✨ Click will auto copy to clipboard</div>
            </div>
            <div className="text-center p-0 md:p-4 text-slate-600">
                Subnet.im is a simple subnetting online cheat sheet, it's also got a built in helpful subnet calculator tool.
                Just enter the IP Address and Mask above, as shown in the example, and the page will auto update.
            </div>
        </div>
    )
})