import { observer } from "mobx-react-lite";
import React from "react";
import { toast } from "react-toastify";
import { useStore } from "../../app/stores/store";
import SubnetDetailsCIDRItem from "./SubnetDetailsCIDRItem";
import SubnetDetailsIPItem from "./SubnetDetailsIPItem";
import SubnetDetailsItem from "./SubnetDetailsItem";
import SubnetCheatSheetRow from "./SubnetCheatSheetRow";

export default observer(function SubnetCheatSheet() {

    const subnets = Array.from(Array(33).keys()).reverse()
    
    const {subnetStore} = useStore();
    if(subnetStore.currentSubnet == undefined) return null;

    return (
        <div className="m-1 md:m-4 md:px-32 pt-4">
            <h1 className="font-extrabold text-transparent text-xl bg-clip-text bg-gradient-to-r from-pink-600 to-purple-500 text-center">Cheat Sheet</h1>
            <div className="font-sans
                        text-center
                        text-sm
                        pb-1
                        text-slate-600"
                >✨ Click on a row to change the subnet mask</div>
            <div className="w-full text-sm md:text-lg">
            <div className={`flex justify-center font-bold hover:bg-slate-100 rounded-full`}>
                <div className={`text-center w-16 `}>CIDR </div>
                <div className="text-center w-64 text-sky-700">Mask</div>
                <div className="text-center w-64 text-purple-700">IPs</div>
                <div className="text-center w-64 text-slate-700">WildCard</div>
            </div>
        </div>
            { subnets.map((subnet) => (
                    <SubnetCheatSheetRow cidr={subnet} key={subnet} />
                ))}
        </div>
    )
})