import React from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

export default observer( function NavBar(){
    
    return (
        <nav className="bg-white shadow-lg">
            <div className="mx-auto px-4">
                <div className="flex justify-between">
                    <div className="flex space-x-7">
                        <div>
                            <a href="/" className="flex items-center py-4 px-2">
                                <span className="font-extrabold text-transparent text-2xl bg-clip-text bg-gradient-to-r from-emerald-600 to-lime-500 hover:from-pink-600 hover:to-purple-500">SUBNET.IM</span>
                            </a>
                        </div>
                        <div className="hidden md:flex items-center space-x-1">
                            <Link to='/'
                                className="py-4 px-2 text-slate-700 hover:border-b-4 hover:border-slate-100 font-semibold"
                                >Home</Link>
                            <Link to='/about'
                            className="py-4 px-2 text-slate-700 hover:border-b-4 hover:border-slate-100 font-semibold"
                                >About</Link>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
})