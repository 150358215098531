import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useStore } from "../../app/stores/store";
import CalculatorForm from "./CalculatorForm";
import SubnetCheatSheet from "./SubnetCheatSheet";
import SubnetDetails from "./SubnetDetails";
import SubnetLink from "./SubnetLink";

export default observer(function AboutPage() {

    const {subnetStore} = useStore();

    return (
        <div className="pt-2">
            <h1 className="font-extrabold text-transparent text-2xl bg-clip-text bg-gradient-to-r from-pink-600 to-purple-500 text-center pt-10">About Us</h1>
            <div className="w-full md:w-2/3 mx-auto text-center py-6 text-lg">
                <div className="py-1 md:py-3">
                    Subnet.im is a simple subnetting cheat sheet website, with a great little subnet calculator integrated. We're always aiming to keep the website clean, fast and useful for everyone to use with ease.
                </div>
                <div className="py-1 md:py-3">
                    The tool will take an entered IP address and netmask (provided in slash notation), it will then calculate the subnet that this address resides within and display the resulting network and broadcast addresses, along with the usable hosts, all of this is shown in normal human readable format but also binary format.
                </div>
                <div className="py-1 md:py-3">
                    IT system engineers and especially network administrators will find themselves performing subnetting tasks on a daily basis, this tool aims to speed up the process of doing this by hand or mentally, and also increase the accuracy, a number of features also exist to automatically show usable ACL rules based on the subnet entered, for example Cisco IP standard or extended ACL's along with IPTables examples.
                </div>
                <div className="py-1 md:py-3">
                    We’ve found that many of the subletting web based tools out there are just so clunky to use, too many drop downs and hard to visually see what’s going on, this website improves on the norm and we’re also planning on providing a number of cool blog posts on how to understand subletting better!
                </div>
                <div className="py-1 md:py-3">
                    If you like what you see, please don’t forget to spread the word :)
                </div>
                <div className="py-1 md:py-3">
                    Also here's some example pages:
                </div>
                <div className="grid grid-cols-1 space-y-2">
                    <Link to="/192.168.0.1/24" className="text-lime-500 hover:text-pink-700 underline font-bold">The typical - 192.168.0.1/24</Link>
                    <Link to="/8.8.8.0/27" className="text-lime-500 hover:text-pink-700 underline font-bold">Google DNS - 8.8.8.0/27</Link>
                    <Link to="/4.4.4.2/28" className="text-lime-500 hover:text-pink-700 underline font-bold">Level 3 DNS - 4.4.4.2/28</Link>
                    <Link to="/1.1.1.1/28" className="text-lime-500 hover:text-pink-700 underline font-bold">CloudFlare - 1.1.1.1/28</Link>
                </div>
                <div className="py-1 md:py-3">
                    You can also see things like RFC1918 - This defines private IP's, they are:
                </div>
                <div className="grid grid-cols-1 space-y-2">
                    <Link to="/192.168.0.1/16" className="text-lime-500 hover:text-pink-700 underline font-bold">192.168.0.1/16</Link>
                    <Link to="/172.16.0.0/12" className="text-lime-500 hover:text-pink-700 underline font-bold">172.16.0.0/12</Link>
                    <Link to="/10.0.0.0/8" className="text-lime-500 hover:text-pink-700 underline font-bold">10.0.0.0/8</Link>
                </div>
            </div>
        </div>
    )
})