import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useStore } from "../../app/stores/store";
import CalculatorForm from "./CalculatorForm";
import SubnetCheatSheet from "./SubnetCheatSheet";
import SubnetDetails from "./SubnetDetails";
import SubnetLink from "./SubnetLink";

export default observer(function PostList() {

    const {subnetStore} = useStore();

    return (
        <div className="pt-2">
            
            <CalculatorForm />
            <div className="grid grid-cols-1 lg:grid-cols-2">
                <SubnetDetails />
                <SubnetLink />
            </div>
            <div>
                <SubnetCheatSheet />
            </div>
        </div>
    )
})