import { observer } from "mobx-react-lite";
import React from "react";
import { useStore } from "../../app/stores/store";
import SubnetDetailsCIDRItem from "./SubnetDetailsCIDRItem";
import SubnetDetailsIPItem from "./SubnetDetailsIPItem";
import SubnetDetailsItem from "./SubnetDetailsItem";

export default observer(function SubnetDetails() {

    const {subnetStore} = useStore();
    
    if(subnetStore.currentSubnet == undefined) return null;

    return (
        <div className="bg-white border-slate-300 px-2 md:px-4 py-1 md:py-4 mx-1 md:mx-4">
            <div className="pb-2">
                <p className="font-extrabold text-transparent text-xl bg-clip-text bg-gradient-to-r from-pink-600 to-purple-500 text-center">{subnetStore.currentSubnet.ipLowStr}/{subnetStore.currentSubnet.prefixSize.toString()}</p>
            </div>
            <div className="space-y-1 md:space-y-2">
                <SubnetDetailsIPItem name="Start IP" ipString={subnetStore.currentSubnet.ipLowStr} ipInt={subnetStore.currentSubnet.ipLow} />
                <SubnetDetailsIPItem name="End IP" ipString={subnetStore.currentSubnet.ipHighStr} ipInt={subnetStore.currentSubnet.ipHigh} />
                <SubnetDetailsIPItem name="Netmask" ipString={subnetStore.currentSubnet.prefixMaskStr} ipInt={subnetStore.currentSubnet.prefixMask}/>
                <SubnetDetailsCIDRItem name="CIDR" cidr={subnetStore.currentSubnet.prefixSize} />
                <SubnetDetailsIPItem name="Wildcard Mask" ipString={subnetStore.currentSubnet.invertedMaskStr} ipInt={subnetStore.currentSubnet.invertedMask} />
            </div>
        </div>
    )
})