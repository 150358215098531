
import React from "react";
import { observer } from "mobx-react-lite";
import IpSubnetCalculator from "ip-subnet-calculator";

interface Props
{
    name: string;
    ipString: string;
    ipInt: number;
}

function ipToBinary (input: string) {

    if (typeof input !== 'string') {
		throw new TypeError(`Expected a string, got ${typeof input}`);
	}

	if(IpSubnetCalculator.isIp(input)){
		var fields = input.split('.');
		var f1 = ("00000000" + parseInt(fields[0]).toString(2)).slice(-8);
		var f2 = ("00000000" + parseInt(fields[1]).toString(2)).slice(-8);
		var f3 = ("00000000" + parseInt(fields[2]).toString(2)).slice(-8);
		var f4 = ("00000000" + parseInt(fields[3]).toString(2)).slice(-8);
		return f1 + "." + f2 + "." + f3 + "." + f4;
	}
    else {
        return "";
    }
}

export default observer(function SubnetDetailsIPItem(props: Props) {

    return (
        <div className="bg-slate-100 rounded-lg hover:bg-purple-50">
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3">
                <div className="flex-none text-center font-bold text-sky-900 py-1 md:pr-4 md:text-right">{props.name}</div>
                <div className="
                py-0 md:py-1
                font-sans
                tracking-widest
                text-center
                md:text-left
                md:col-span-2 ml-1 md:ml-0">
                    <div className="font-medium">{props.ipString}</div>
                    <div className="text-sm text-slate-500"><span className="pr-2">Bin:</span> {ipToBinary(props.ipString)}</div>
                    <div className="text-sm text-slate-500"><span className="pr-3">Int:</span> {props.ipInt} <span className="pr-3 pl-6"> Hex:</span> 0x{props.ipInt.toString(16)}</div>
                </div>
            </div>
        </div>
    )
})