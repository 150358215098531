import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import '../../App.css';
import AboutPage from '../../features/calculator/AboutPage';
import PostList from '../../features/calculator/CalculatorDashboard';
import ModalContainer from '../common/modals/ModalContainer';
import { useStore } from '../stores/store';
import LoadingComponent from './LoadingComponent';
import NavBar from './NavBar';

function App() {

    const {commonStore} = useStore();
  
    return (
        <>
            <ToastContainer position='bottom-right' hideProgressBar />
            <NavBar />
            <ModalContainer />
            <Routes>
                <Route path="/" element={<PostList />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/:urlIp" element={<PostList />} />
                <Route path="/:urlIp/:urlCidr" element={<PostList />} />
            </Routes>
        </>
    );
}

export default App;