import IpSubnetCalculator from "ip-subnet-calculator";
import { observer } from "mobx-react-lite";
import React from "react";
import { useStore } from "../../app/stores/store";

interface Props{
    cidr: number;
}

export default observer(function SubnetCheatSheetRow(props: Props) {

    const {subnetStore} = useStore();
    if(subnetStore.currentSubnet == undefined) return null;

    const isSelected: boolean = subnetStore.currentSubnet.prefixSize === props.cidr;

    var mask = IpSubnetCalculator.calculateSubnetMask("127.0.0.1", props.cidr);

    const classEdges = [0,8,16,24,32];
    const isClassEdge = classEdges.indexOf(props.cidr) !== -1;

    return (
        <div className="w-full text-sm md:text-lg">
            <div
                className={`flex justify-center ${isSelected && "bg-purple-100" } ${isClassEdge && "py-1"} hover:bg-slate-100 rounded-full`}
                onClick={() => subnetStore.cidrChange(props.cidr.toString())}
            >
                <div className={`text-center ${isClassEdge ? "text-lime-500" : "text-emerald-600"} w-16 font-bold `}>
                    <span className="text-slate-400 text-xs">/</span> {props.cidr}
                </div>
                <div className="text-center w-64 text-sky-700">
                    {mask.prefixMaskStr}
                </div>
                <div className="text-center w-64 text-purple-700">
                    {(2 ** mask.invertedSize).toLocaleString()}
                </div>
                <div className="text-center w-64 text-slate-700">
                    {mask.invertedMaskStr}
                </div>
            </div>
        </div>
    )
})