
import React from "react";
import { observer } from "mobx-react-lite";
import IpSubnetCalculator from "ip-subnet-calculator";

interface Props
{
    name: string;
    cidr: number;
}

export default observer(function SubnetDetailsCIDRItem(props: Props) {

    return (
        <div className="bg-slate-100 rounded-lg hover:bg-purple-50">
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3">
                <div className="text-center font-bold text-sky-900 py-1 md:pr-4 md:text-right">{props.name}</div>
                <div className="py-0 md:py-1
                font-sans
                tracking-widest
                text-center
                md:text-left
                md:col-span-2 ml-1 md:ml-0">
                    <div className="font-medium">{props.cidr}</div>
                    <div className="text-sm"><span className="pr-3">Int:</span> {props.cidr.toString()} <span className="pr-3 pl-6"> Hex:</span> 0x{props.cidr.toString(16)}</div>
                </div>
            </div>
        </div>
    )
})