import IpSubnetCalculator from "ip-subnet-calculator";
import { makeAutoObservable, runInAction } from "mobx";
import { useNavigate } from "react-router-dom";
import { history } from "../..";
import agent from "../api/agent";
import { Post, PostFormValues } from "../models/post";

export default class SubnetStore {

    valid: boolean = false;
    currentSubnet: IpSubnetCalculator.SubnetResult | undefined;

    ip: string | undefined;
    cidr: number | undefined;
    
    initialLoad: boolean = false;

    ipValid: boolean = true;
    cidrValid: boolean = true;


    constructor() {
        makeAutoObservable(this);
    }

    init = (ip: string, cidr: string) => {
        this.ipChange(ip);
        this.cidrChange(cidr);
        this.calculate();
        this.initialLoad = true;
    }

    ipChange = (value: string) => {
        this.ip = value;
        if(IpSubnetCalculator.isIp(this.ip))
        {
            this.ipValid = true;
            this.calculate();
        }
        else{
            this.ipValid = false;
        }
    }

    cidrChange = (value: string) => {
        this.cidr = +value;

        if(this.cidr >= 0 && this.cidr <= 32)
        {
            this.cidrValid = true;
            this.calculate();
        }
        else{
            this.cidrValid = false;
        }
    }

    updateCidr = (value: string) => {
        this.cidr = +value;
        this.calculate();
    }

    get getCurrentNetmask() {
        return this.currentSubnet?.prefixMaskStr;
    } 

    // Refresh the content
    private calculate = () => {
        if(this.ip && this.cidr)
            this.currentSubnet = IpSubnetCalculator.calculateSubnetMask(
                this.ip, this.cidr)
    }

}
