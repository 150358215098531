import React, { ChangeEventHandler, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../app/stores/store";
import IpSubnetCalculator from "ip-subnet-calculator"

export default observer(function PostSubmitForm() {

    const navigate = useNavigate();
    const {subnetStore} = useStore();
    const {ipChange, cidrChange} = subnetStore;

    const [ip, setIp] = useState("192.168.0.0");
    const [cidr, setCidr] = useState("24");

    const {urlIp, urlCidr} = useParams<{urlIp: string, urlCidr: string}>();

    useEffect(() => {
        if(urlIp) {
            setIp(urlIp);
            ipChange(urlIp);
        }
        if(urlCidr) {
            setCidr(urlCidr);
            cidrChange(urlCidr);
        }
    }, [urlIp, urlCidr, setIp, setCidr, ipChange, cidrChange]);


    function onIpChange (e: React.ChangeEvent<HTMLInputElement>) {
        setIp(e.currentTarget.value);
        ipChange(e.currentTarget.value);
    }

    function onCidrChange (e: React.ChangeEvent<HTMLInputElement>) {
        setCidr(e.currentTarget.value);
        cidrChange(e.currentTarget.value);
    }

    function updateUrl() {
        navigate(`/${ip}/${cidr}`);
    }

    if(!subnetStore.initialLoad) subnetStore.init(ip, cidr)

    return (
        <div className="px-8 pt-4 pb-4">
            <div className="md:items-center">
                <div className="flex items-center justify-center">
                    {subnetStore.ipValid ? (
                        <input
                            className="bg-white appearance-none border-2 border-green-700 rounded w-48 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-green-500"
                            value={ip}
                            onChange={(e) => onIpChange(e)} />
                    ) : (
                        <input
                            className="bg-red-100 appearance-none border-2 border-red-400 rounded w-48 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-red-500"
                            value={ip}
                            onChange={(e) => onIpChange(e)} />
                    )}
                    <div className="px-3 tax font-bold text-slate-500">
                        /   
                    </div>
                    {subnetStore.cidrValid ? (
                        <input
                            className="bg-white appearance-none border-2 border-green-700 rounded py-2 w-20 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-green-500"
                            value={cidr}
                            onChange={(e) => onCidrChange(e)} />
                    ) : (
                        <input
                            className="bg-red-100 appearance-none border-2 border-red-400 rounded py-2 w-20 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-red-500"
                            value={cidr}
                            onChange={(e) => onCidrChange(e)} />
                    )}
                </div>
            </div>
        </div>
    )
})